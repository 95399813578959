import * as React from "react";

import { ReactComponent as Spinner } from "../../static/assets/spinner.svg";

import "./CustomLoader.scss";

export const CustomLoader: React.FC = props => (
  <div className="custom-loader">
    <Spinner />
  </div>
);

export default CustomLoader;
