import * as React from "react";
import "./ConfirmModal.scss";

interface ConfirmModalProps {
  id?: string;
  open?: boolean;
  buttonText?: string;
  buttonText2?: string;
  onButtonClick?: () => void;
  onButton2Click?: () => void;
  onClose?: () => void;
}

interface ConfirmModalState {
  open: boolean;
}

export class ConfirmModal extends React.Component<ConfirmModalProps, ConfirmModalState> {
  public readonly state: ConfirmModalState = {
    open: false
  };

  constructor(props: ConfirmModalProps) {
    super(props);
    this.state.open = props.open || false;
  }

  componentWillReceiveProps(nextProps: ConfirmModalProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  public render() {
    const { open } = this.state;
    const { id, buttonText, buttonText2 } = this.props;
    return (
      <div id={id} className={`modal ${open ? "is-active" : ""} confirm-modal`}>
        <div id={`${id}-background`} className="modal-background" onClick={() => this.closeModal()}></div>
        <div className="modal-content">
          <div className="content">{this.props.children}</div>
          <div className="modal-buttons">
            {buttonText && (
              <a
                id={`${id}-first-btn`}
                href="javascript:;"
                className="modal-button"
                onClick={() => {
                  this.handleButtonClick();
                }}>
                {buttonText}
              </a>
            )}

            {buttonText2 && (
              <a
                id={`${id}-second-btn`}
                href="javascript:;"
                className="modal-button"
                onClick={() => {
                  this.handleButton2Click();
                }}>
                {buttonText2}
              </a>
            )}
          </div>

          <a
            id={`${id}-close-btn`}
            href="javascript:;"
            className="modal-close is-large"
            aria-label="close"
            onClick={() => this.closeModal()}>
            <span className="visually-hidden">Close Modal</span>
          </a>
        </div>
      </div>
    );
  }

  private handleButtonClick() {
    const { onButtonClick } = this.props;
    if (onButtonClick) {
      onButtonClick();
    } else {
      this.closeModal();
    }
  }

  private handleButton2Click() {
    const { onButton2Click } = this.props;
    if (onButton2Click) {
      onButton2Click();
    } else {
      this.closeModal();
    }
  }

  private closeModal() {
    const { onClose } = this.props;
    this.setState({ open: false });
    if (onClose) {
      this.props.onClose();
    }
  }
}
