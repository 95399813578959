import * as React from "react";
import { Link } from "react-router-dom";
import Div100vh from "react-div-100vh";

import logo from "../static/assets/ceres-logo-white.svg";
import logoRed from "../static/assets/ceres-logo-red.svg";
import { Modal, CustomLoader } from "../components";
import { AppContext } from "../context/AppContext";
import { useAppDataQuery } from "../generated/graphql";
import "./Base.scss";
import { Footer } from "./Footer";

interface BaseLayoutProps {
  appLoading: boolean;
}

const toggleMenu = () => {
  document.querySelector(".navbar-burger").classList.toggle("is-active");
  document.querySelector("#navbar-menu").classList.toggle("is-active");
  document.querySelector("body").classList.toggle("no-scroll");
};

export const BaseLayout: React.FC<BaseLayoutProps> = props => {
  const { data, error, loading } = useAppDataQuery();

  if (props.appLoading || loading) {
    return <CustomLoader />;
  }

  if (error || !data) {
    return <div>ERROR</div>;
  }

  setTimeout(() => {
    document.querySelectorAll('a[href^="https://"]').forEach(x => {
      const ele = x as HTMLAnchorElement;
      ele.target = "_blank";
    });
    document.querySelectorAll("a").forEach(x => {
      const ele = x as HTMLAnchorElement;
      ele.removeAttribute("title");
    });
  }, 0);
  return (
    <Div100vh>
      <AppContext.Consumer>
        {({ windowWidth, showWelcomeModal, updateState }) => {
          const isMobile = windowWidth < 1024;
          const url = window.location.href;
          let menuSelected = "home";
          if (showWelcomeModal) {
            menuSelected = showWelcomeModal;
          } else if (url.indexOf("categories/") > -1 || url.indexOf("pantry") > -1) {
            menuSelected = "pantry";
          }
          return (
            <div id="base-layout" className="hero is-fullheight">
              <div className="hero-head">
                <nav className="navbar is-primary is-transparent" role="navigation" aria-label="main navigation">
                  <div className="container">
                    <a
                      id="back-btn"
                      className="back-btn"
                      onClick={() => {
                        if (
                          document.querySelector(".pantry-container") &&
                          document.querySelector(".pantry-container").classList.contains("open")
                        ) {
                          (document.querySelector("body") as HTMLElement).classList.remove("no-scroll");
                          (document.querySelector(".pantry-container") as HTMLElement).classList.remove("open");
                        } else {
                          window.history.back();
                        }
                      }}>
                      <i className="fas fa-chevron-left fa-lg"></i>
                    </a>

                    <div className="navbar-brand">
                      <Link to="/categories" id="logo-white" className="navbar-item logo-white-container">
                        <span className="visually-hidden">Ceres Organics</span>
                        <img src={logo} alt="Ceres Organics" width="125" height="60" />
                      </Link>

                      <Link to="/categories" id="logo-red" className="navbar-item logo-red-container">
                        <span className="visually-hidden">Ceres Organics</span>
                        <img src={logoRed} alt="Ceres Organics" width="125" height="60" />
                      </Link>

                      <h1 className="visually-hidden">Ceres Organics</h1>
                      <a
                        id="menu-link"
                        onClick={() => {
                          if (isMobile) {
                            toggleMenu();
                          }
                        }}
                        role="button"
                        className="navbar-burger burger"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarMenu">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span className="visually-hidden">Menu</span>
                      </a>
                    </div>
                    <div id="navbar-menu" className="navbar-menu">
                      <div className="navbar-start">{/* <a className="navbar-item">Home</a> */}</div>
                      <div className="navbar-end">
                        <Link
                          id="home-link"
                          to="/categories"
                          className={`navbar-item ${menuSelected === "home" ? "active" : ""}`}
                          onClick={() => {
                            if (isMobile) {
                              toggleMenu();
                            }
                          }}>
                          HOME
                        </Link>
                        <a
                          id="how-to-enter-link"
                          href="javascript:;"
                          className={`navbar-item ${menuSelected === "howToEnter" ? "active" : ""}`}
                          onClick={() => {
                            if (isMobile) {
                              toggleMenu();
                            }
                            updateState({ showWelcomeModal: "howToEnter" });
                          }}>
                          HOW TO ENTER
                        </a>
                        <a
                          id="prize-link"
                          href="javascript:;"
                          className={`navbar-item ${menuSelected === "prizes" ? "active" : ""}`}
                          onClick={() => {
                            if (isMobile) {
                              toggleMenu();
                            }
                            updateState({ showWelcomeModal: "prizes" });
                          }}>
                          PRIZES
                        </a>
                        <a
                          id="terms-link"
                          href="https://ceres.co.nz/pantry-makeover-terms-conditions/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`navbar-item`}
                          onClick={() => {
                            if (isMobile) {
                              toggleMenu();
                            }
                          }}>
                          TERMS
                        </a>
                        <Link
                          id="my-pantry-link"
                          to="/pantry"
                          className={`navbar-item ${menuSelected === "pantry" ? "active" : ""}`}
                          onClick={() => {
                            if (isMobile) {
                              toggleMenu();
                            }
                          }}>
                          MY PANTRY
                        </Link>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="container">
                {props.children}
                {/* <div id="base-layout-content"></div> */}
              </div>
              {data.popups.map(x => {
                return (
                  <Modal
                    key={x.name}
                    id={x.name}
                    open={showWelcomeModal === x.name}
                    additionalContent={x.terms.html}
                    onClose={() => {
                      updateState({ showWelcomeModal: null });
                    }}>
                    <div dangerouslySetInnerHTML={{ __html: x.content.html }} />
                  </Modal>
                );
              })}

              <Footer />
            </div>
          );
        }}
      </AppContext.Consumer>
    </Div100vh>
  );
};

export default BaseLayout;
