import * as React from "react";

export const ImageModal: React.FC = props => (
  <div id="image-modal" className="modal">
    <div
      className="modal-background"
      onClick={() => {
        (document.querySelector("#image-modal") as HTMLElement).classList.toggle("is-active");
      }}></div>
    <div
      className="modal-content"
      onClick={() => {
        (document.querySelector("#image-modal") as HTMLElement).classList.toggle("is-active");
      }}>
      <p className="image is-square">
        <img id="image-modal" src="" alt="" />
      </p>
    </div>
    <button
      className="modal-close is-large"
      aria-label="close"
      onClick={() => {
        (document.querySelector("#image-modal") as HTMLElement).classList.toggle("is-active");
      }}></button>
  </div>
);

export default ImageModal;
