import * as React from "react";
import { Product, Category } from "../generated/graphql";
import { toast, Zoom } from "react-toastify";

export interface CartItem {
  category: Category;
  product: Product;
}

interface AppProviderProps {
  children: React.ReactNode;
}

interface AppProviderState {
  name?: string;
  email?: string;
  cart?: CartItem[];
  showWelcomeModal?: string;
  showConflictProductModal?: boolean;
  selectedCartItem?: CartItem;
  windowHeight?: number;
  windowWidth?: number;
}

interface AppContextProps extends AppProviderState {
  updateState: (data: AppProviderState) => void;
  addSelectedCartItemToCart: () => void;
  clearSelectedCartItem: () => void;
  addProductCategoryToCart: (product: Product, category: Category) => boolean;
  removeProductCategoryFromCart: (product: Product, category: Category) => void;
}

export const DefaultState: AppProviderState = {
  name: "",
  email: "",
  cart: [],
  showWelcomeModal: null,
  showConflictProductModal: false,
  selectedCartItem: null,
  windowHeight: undefined,
  windowWidth: undefined
};

export const AppContext = React.createContext<Partial<AppContextProps>>(DefaultState);

export class AppProvider extends React.Component<AppProviderProps, AppProviderState> {
  public readonly state: AppProviderState = { ...DefaultState };

  public resizeTimer = null;

  public componentDidMount() {
    const cart = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : [];
    let showWelcomeModal = null;
    if (window.location.pathname === "/") {
      setTimeout(() => {
        this.setState({ showWelcomeModal: "welcome" });
      }, 3000);
    }
    this.setState({ cart, windowHeight: window.innerHeight, windowWidth: window.innerWidth, showWelcomeModal });

    window.addEventListener("resize", () => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.setState({ windowHeight: window.innerHeight, windowWidth: window.innerWidth });
      }, 100);
    });
  }

  public render() {
    const { children } = this.props;
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          updateState: this.updateState,
          addSelectedCartItemToCart: this.addSelectedCartItemToCart,
          clearSelectedCartItem: this.clearSelectedCartItem,
          addProductCategoryToCart: this.addProductCategoryToCart,
          removeProductCategoryFromCart: this.removeProductCategoryFromCart
        }}>
        {children}
      </AppContext.Provider>
    );
  }

  private updateState = (data: AppProviderState) => {
    this.setState({ ...data });
  };

  private addSelectedCartItemToCart = () => {
    const { cart, selectedCartItem } = this.state;
    const cartCategory = cart.find(x => x.category.id === selectedCartItem.category.id);
    const idx = cartCategory.category.products.findIndex(x => x.id === cartCategory.product.id);
    this.analyticsProductAddRemoveToCart(cartCategory.product, cartCategory.category, idx, "removeFromCart");
    cartCategory.product = selectedCartItem.product;
    this.setState({ cart, selectedCartItem: null });
    sessionStorage.setItem("cart", JSON.stringify(cart));
    toast.success(`Yum! ${cartCategory.product.name} was added to your pantry!`, {
      transition: Zoom,
      position: "bottom-right"
    });
    this.analyticsProductAddRemoveToCart(selectedCartItem.product, cartCategory.category, idx, "addToCart");
  };

  private clearSelectedCartItem = () => {
    this.setState({ selectedCartItem: null });
  };

  private addProductCategoryToCart = (product: Product, category: Category) => {
    const { cart } = this.state;
    const cartCategory = cart.find(x => x.category.id === category.id);
    const idx = category.products.findIndex(x => x.id === product.id);
    // always track click
    this.analyticsProductClick(product, category, idx);
    if (cartCategory && cartCategory.product.id !== product.id) {
      this.setState({ selectedCartItem: { product, category } as CartItem, showConflictProductModal: true });
      return false;
    } else if (cartCategory && cartCategory.product.id === product.id) {
      this.setState({ cart, selectedCartItem: { product, category }, showConflictProductModal: true });
      return false;
    } else {
      toast.success(`Yum! ${product.name} was added to your pantry!`, {
        transition: Zoom,
        position: "bottom-right"
      });
      cart.push({ product, category });
      this.setState({ cart });
      sessionStorage.setItem("cart", JSON.stringify(cart));
      // only check add to cart here
      this.analyticsProductAddRemoveToCart(product, category, idx, "addToCart");
      return true;
    }
  };

  private analyticsProductClick = (product: Product, category: Category, idx: number) => {
    // @ts-ignore
    if (window && window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        ecommerce: {
          click: {
            products: [
              {
                id: product.id,
                name: product.name,
                category: category.name,
                position: idx
              }
            ]
          }
        }
      });
    }
  };

  private analyticsProductAddRemoveToCart = (product: Product, category: Category, idx: number, type: string) => {
    // @ts-ignore
    if (window && window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        event: type,
        ecommerce: {
          currencyCode: "NZD",
          add: {
            products: [
              {
                id: product.id,
                name: product.name,
                category: category.name,
                position: idx,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  };

  private removeProductCategoryFromCart = (product: Product, category: Category) => {
    const { cart } = this.state;
    const cartProductIdx = cart.findIndex(x => x.product.id === product.id);
    const idx = category.products.findIndex(x => x.id === product.id);
    if (cartProductIdx !== -1) {
      cart.splice(cartProductIdx, 1);
      this.setState({ cart });
      sessionStorage.setItem("cart", JSON.stringify(cart));
      toast.success(`${product.name} was removed from your pantry!`, {
        transition: Zoom,
        position: "bottom-right"
      });
      this.analyticsProductAddRemoveToCart(product, category, idx, "removeFromCart");
    }
  };
}

export default AppProvider;
