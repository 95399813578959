import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { persistCache, CachePersistor } from "apollo-cache-persist";
import ApolloClient, { InMemoryCache } from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { toast } from "react-toastify";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import { AsyncComponent, CustomLoader } from "./components";
import AppProvider from "./context/AppContext";
import BaseLayout from "./layout/BaseLayout";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-toastify/dist/ReactToastify.css";
import "./static/fonts/LoveJones/stylesheet.css";
import "./static/fonts/CenturyGothicStd/stylesheet.css";
import "./static/fonts/ErnestAndEmily/stylesheet.css";
import "./static/fonts/Argone/stylesheet.css";
import "./styles/styles.scss";

const now = new Date();
const SCHEMA_VERSION = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}}-1`;
const SCHEMA_VERSION_KEY = "apollo-schema-version";
const CategoriesContainer = AsyncComponent(() => import("./modules/Categories"));
const CategoryProductsContainer = AsyncComponent(() => import("./modules/CategoryProducts"));
const PantryContainer = AsyncComponent(() => import("./modules/Pantry"));
const EnterFormContainer = AsyncComponent(() => import("./modules/EnterForm"));
const CompleteContainer = AsyncComponent(() => import("./modules/Complete"));
const PrintContainer = AsyncComponent(() => import("./modules/Print"));

const NotFoundContainer = AsyncComponent(() => import("./modules/NotFound/NotFound"));

interface AppState {
  client: ApolloClient<any>;
  loading: boolean;
}

class App extends React.Component<{}, AppState> {
  public readonly state: AppState = {
    client: null,
    loading: true
  };

  async componentDidMount() {
    toast.configure({ autoClose: 4000 });
    const client = await this.configureApolloClient();
    this.setState({ client, loading: false });
  }

  async configureApolloClient() {
    const uri = process.env.REACT_APP_GRAPHQL_URL;
    const cache = new InMemoryCache();
    const persistor = new CachePersistor({
      cache,
      maxSize: false,
      storage: sessionStorage
    });
    const client = new ApolloClient({ uri, cache });
    try {
      // Read the current schema version from sessionStorage.
      const currentVersion = sessionStorage.getItem(SCHEMA_VERSION_KEY);
      // if url has clear cache in it, then we clear the cache
      if (currentVersion === SCHEMA_VERSION && window.location.search.indexOf("clearCache") === -1) {
        // If the current version matches the latest version,
        // we're good to go and can restore the cache.
        await persistor.restore();
      } else {
        // Otherwise, we'll want to purge the outdated persisted cache
        // and mark ourselves as having updated to the latest version.
        await persistor.purge();
        sessionStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
      }
      // See above for additional options, including other storage providers.
      await persistCache({
        cache,
        maxSize: false,
        storage: window.sessionStorage
      });
    } catch (error) {
      console.error("Error restoring Apollo cache", error);
    }
    return client;
  }

  render() {
    const { client, loading } = this.state;
    if (loading) {
      return <CustomLoader />;
    }
    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <BrowserRouter>
            <AppProvider>
              <DndProvider backend={HTML5Backend}>
                <BaseLayout appLoading={loading}>
                  <Switch>
                    <Route path="/" exact component={CategoriesContainer} />
                    <Route path="/categories" exact component={CategoriesContainer} />
                    <Route path="/categories/:name" exact component={CategoryProductsContainer} />
                    <Route path="/pantry" exact component={PantryContainer} />
                    <Route path="/enter" exact component={EnterFormContainer} />
                    <Route path="/complete" component={CompleteContainer} />
                    <Route path="/print/:ids" exact component={PrintContainer} />
                    <Route component={NotFoundContainer} />
                  </Switch>
                </BaseLayout>
              </DndProvider>
            </AppProvider>
          </BrowserRouter>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default App;
