import * as React from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import onePxImage from "../../static/assets/px.png";
import playImage from "../../static/assets/PLAY.png";
import playImageWhite from "../../static/assets/PLAY_WHITE.png";

import "./Modal.scss";

interface ModalProps {
  id: string;
  additionalContent?: string;
  open?: boolean;
  closeOnBackground?: boolean;
  buttonHandle?: string;
  buttonActiveHandle?: string;
  onButtonClick?: () => void;
  onClose?: () => void;
}

interface ModalState {
  open: boolean;
}

export class Modal extends React.Component<ModalProps, ModalState> {
  public readonly state: ModalState = {
    open: false
  };

  constructor(props: ModalProps) {
    super(props);
    this.state.open = props.open || false;
  }

  componentWillReceiveProps(nextProps: ModalProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }

    setTimeout(() => {
      const link = document.querySelector(".prize-link a") as HTMLLinkElement;
      if (link) {
        link.onclick = function(e) {
          e.preventDefault();
          (document.querySelector("#prize-link") as HTMLLinkElement).click();
        };
      }

      const link2 = document.querySelector(".how-link a") as HTMLLinkElement;
      if (link2) {
        link2.onclick = function(e) {
          e.preventDefault();
          (document.querySelector("#how-to-enter-link") as HTMLLinkElement).click();
        };
      }
    }, 500);
  }

  public render() {
    const { open } = this.state;
    return (
      <div id={this.props.id} className={`modal ${open ? "is-active" : ""} custom-modal`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <div className="modal-card-header">
            <a
              id={`${this.props.id}-close-btn`}
              href="javascript:;"
              className="modal-close is-large"
              aria-label="close"
              onClick={() => this.closeModal()}>
              <span className="visually-hidden">Close Modal</span>
            </a>
          </div>

          <div className="modal-card-body">
            <div className="content">{this.props.children}</div>
          </div>
          <div className="modal-card-foot">
            <a
              href="javascript:;"
              id={`${this.props.id}-main-btn`}
              className="modal-button"
              onClick={() => {
                this.handleButtonClick();
              }}>
              <LazyLoadImage
                placeholderSrc={onePxImage}
                className="modal-button-img"
                effect="opacity"
                src={playImage}
                delayTime={0}
                visibleByDefault={true}
              />
              <LazyLoadImage
                placeholderSrc={onePxImage}
                className="modal-button-active-img"
                effect="opacity"
                src={playImageWhite}
                delayTime={0}
                visibleByDefault={true}
              />
              <span className="visually-hidden">PLAY</span>
            </a>
          </div>
          {this.props.additionalContent && (
            <div className="modal-card-terms" dangerouslySetInnerHTML={{ __html: this.props.additionalContent }} />
          )}
        </div>
      </div>
    );
  }

  private handleButtonClick() {
    const { onButtonClick } = this.props;
    if (onButtonClick) {
      onButtonClick();
    } else {
      this.closeModal();
    }
  }

  private handleBackgroundClick() {
    const { closeOnBackground } = this.props;
    if (closeOnBackground) {
      this.closeModal();
    }
  }

  private closeModal() {
    const { onClose } = this.props;
    this.setState({ open: false });
    if (onClose) {
      this.props.onClose();
    }
  }
}
