import * as React from "react";

export const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <footer id="colophon" className="site-footer" role="contentinfo">
      <div id="ceres-social">
        <a href="https://www.facebook.com/CeresOrganicsNZ/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-f fa-lg" />
        </a>
        <a href="https://instagram.com/ceresorganics/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram fa-lg" />
        </a>
        <a href="https://www.pinterest.com/ceresorganics/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-pinterest-p fa-lg" />
        </a>
        <a href="https://twitter.com/CeresOrganics?lang=en" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-twitter fa-lg" />
        </a>
      </div>
      <div className="footer-nav">
        <div className="menu-header-container">
          <ul id="footer-menu" className="menu">
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-17">
              <a href="https://ceres.co.nz/about-organics/" target="_blank" rel="noopener noreferrer">
                About
              </a>
              <ul className="sub-menu">
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-270">
                  <a href="https://ceres.co.nz/our-story/" target="_blank" rel="noopener noreferrer">
                    Our story
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-274">
                  <a href="https://ceres.co.nz/our-mission-vision/" target="_blank" rel="noopener noreferrer">
                    Mission &amp; Vision
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-277">
                  <a href="https://ceres.co.nz/our-organic-certification/" target="_blank" rel="noopener noreferrer">
                    Our Organic Certification
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-280">
                  <a href="https://ceres.co.nz/ge-free/" target="_blank" rel="noopener noreferrer">
                    Our GE Free Policy
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-283">
                  <a href="https://ceres.co.nz/our-sourcing-philosophy/" target="_blank" rel="noopener noreferrer">
                    Our Sourcing Philosophy
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3511">
                  <a href="https://ceres.co.nz/ceres-gives-back/" target="_blank" rel="noopener noreferrer">
                    Ceres Gives Back
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-289">
                  <a href="https://ceres.co.nz/about-food-allergies/" target="_blank" rel="noopener noreferrer">
                    Food Allergies
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-296">
                  <a href="https://ceres.co.nz/our-new-location/" target="_blank" rel="noopener noreferrer">
                    Our Green Home
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-299">
                  <a href="https://ceres.co.nz/disclaimer/" target="_blank" rel="noopener noreferrer">
                    Disclaimer
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-345">
              <a
                href="https://www.ceres.co.nz/products/grocery/ceres-organics/"
                target="_blank"
                rel="noopener noreferrer">
                Products
              </a>
              <ul className="sub-menu">
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3082">
                  <a
                    href="https://www.ceres.co.nz/products/grocery/ceres-organics/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Ceres Organics
                  </a>
                </li>
                <li className="menu-item menu-item-type-taxonomy menu-item-object-product-categories menu-item-3386">
                  <a href="https://ceres.co.nz/products/grocery/" target="_blank" rel="noopener noreferrer">
                    GROCERY
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5233">
                  <a
                    href="https://www.ceres.co.nz/products/health-and-beauty/natracare-health-and-beauty/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Natracare
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-400">
                  <a href="https://ceres.co.nz/ingredients/" target="_blank" rel="noopener noreferrer">
                    Bulk Ingredients
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-343">
              <a href="https://ceres.co.nz/recipes/" target="_blank" rel="noopener noreferrer">
                Recipes
              </a>
              <ul className="sub-menu">
                <li className="menu-item menu-item-type-taxonomy menu-item-object-recipe-categories menu-item-344">
                  <a href="https://ceres.co.nz/recipes/breakfast/" target="_blank" rel="noopener noreferrer">
                    Breakfast
                  </a>
                </li>
                <li className="menu-item menu-item-type-taxonomy menu-item-object-recipe-categories menu-item-2283">
                  <a href="https://ceres.co.nz/recipes/cake-cookies-muffins/" target="_blank" rel="noopener noreferrer">
                    Sweet Treats
                  </a>
                </li>
                <li className="menu-item menu-item-type-taxonomy menu-item-object-recipe-categories menu-item-2483">
                  <a href="https://ceres.co.nz/recipes/juices-smoothies/" target="_blank" rel="noopener noreferrer">
                    Juices + Smoothies
                  </a>
                </li>
                <li className="menu-item menu-item-type-taxonomy menu-item-object-recipe-categories menu-item-2485">
                  <a href="https://ceres.co.nz/recipes/mains/" target="_blank" rel="noopener noreferrer">
                    Mains
                  </a>
                </li>
                <li className="menu-item menu-item-type-taxonomy menu-item-object-recipe-categories menu-item-2484">
                  <a href="https://ceres.co.nz/recipes/raw-un-baking/" target="_blank" rel="noopener noreferrer">
                    Raw Un-Baking
                  </a>
                </li>
                <li className="menu-item menu-item-type-taxonomy menu-item-object-recipe-categories menu-item-2487">
                  <a href="https://ceres.co.nz/recipes/salads-dressings/" target="_blank" rel="noopener noreferrer">
                    Salads + Dressings
                  </a>
                </li>
                <li className="menu-item menu-item-type-taxonomy menu-item-object-recipe-categories menu-item-2489">
                  <a href="https://ceres.co.nz/recipes/soups/" target="_blank" rel="noopener noreferrer">
                    Soups
                  </a>
                </li>
                <li className="menu-item menu-item-type-taxonomy menu-item-object-recipe-categories menu-item-2488">
                  <a href="https://ceres.co.nz/recipes/snacks/" target="_blank" rel="noopener noreferrer">
                    Snacks
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18">
              <a href="https://ceres.co.nz/blog/" target="_blank" rel="noopener noreferrer">
                Blog
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-19">
              <a href="https://ceres.co.nz/contact-ceres-organics/" target="_blank" rel="noopener noreferrer">
                Contact
              </a>
              <ul className="sub-menu">
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-300">
                  <a href="https://ceres.co.nz/contact-ceres-organics/" target="_blank" rel="noopener noreferrer">
                    Ceres Organics
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6845">
                  <a href="https://careers.ceresorganics.com/" target="_blank" rel="noopener noreferrer">
                    careers
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-311">
                  <a href="https://ceres.co.nz/faq/" target="_blank" rel="noopener noreferrer">
                    Frequently Asked Questions
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-290">
              <a href="https://shop.ceres.co.nz/" target="_blank" rel="noopener noreferrer">
                Shop
              </a>
              <ul className="sub-menu">
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2898">
                  <a href="https://shop.ceres.co.nz/" target="_blank" rel="noopener noreferrer">
                    online store
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-305">
                  <a href="https://ceres.co.nz/locations/" target="_blank" rel="noopener noreferrer">
                    Our Stores
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4973">
                  <a href="https://ceres.co.nz/stockists/" target="_blank" rel="noopener noreferrer">
                    Stockists
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2799">
                  <a href="https://portal.ceres.co.nz/Auth?ReturnUrl=%2F" target="_blank" rel="noopener noreferrer">
                    Trade Portal
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="site-info">
        © CERES ORGANICS {year} <span className="sep"> | </span>{" "}
        <a href="https://ceres.co.nz/privacy-policy/" target="_blank" rel="noopener noreferrer">
          PRIVACY
        </a>
        <span className="sep"> | </span>{" "}
        <a href="https://ceres.co.nz/legal-notice/" target="_blank" rel="noopener noreferrer">
          LEGAL
        </a>
        <span className="sep"> | </span>
        <a href="https://ceres.co.nz/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
          TERMS&nbsp;&amp;&nbsp;CONDITIONS
        </a>
      </div>
    </footer>
  );
};
