import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  /** Custom scalar representing the hex color code value */
  HEX: any,
  /** Custom scalar representing the hue part of a rgba value. Value ranges from 0 - 255 */
  RGBAHue: any,
  /** Custom scalar representing the transparency part of a rgba value. Value ranges from 0 - 1 */
  RGBATransparency: any,
  /** Custom scalar representing a Slate rich text AST */
  RichTextAST: any,
  /** 
 * The `Long` scalar type represents non-fractional signed whole numeric values.
   * Long can represent values between -(2^63) and 2^63 - 1.
 **/
  Long: any,
  /** Raw JSON value */
  Json: any,
};

export type AggregateAsset = {
   __typename?: 'AggregateAsset',
  count: Scalars['Int'],
};

export type AggregateCategory = {
   __typename?: 'AggregateCategory',
  count: Scalars['Int'],
};

export type AggregateCompleteContent = {
   __typename?: 'AggregateCompleteContent',
  count: Scalars['Int'],
};

export type AggregateEntryForm = {
   __typename?: 'AggregateEntryForm',
  count: Scalars['Int'],
};

export type AggregateEntryFormContent = {
   __typename?: 'AggregateEntryFormContent',
  count: Scalars['Int'],
};

export type AggregatePopup = {
   __typename?: 'AggregatePopup',
  count: Scalars['Int'],
};

export type AggregateProduct = {
   __typename?: 'AggregateProduct',
  count: Scalars['Int'],
};

export type Asset = Node & {
   __typename?: 'Asset',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  handle: Scalars['String'],
  fileName: Scalars['String'],
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  imageProduct?: Maybe<Array<Product>>,
  signImageCategory?: Maybe<Array<Category>>,
  circleImageCategory?: Maybe<Array<Category>>,
  labelImageCategory?: Maybe<Array<Category>>,
  /** Get the url for the asset with provided transformations applied. */
  url: Scalars['String'],
};


export type AssetImageProductArgs = {
  where?: Maybe<ProductWhereInput>,
  orderBy?: Maybe<ProductOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type AssetSignImageCategoryArgs = {
  where?: Maybe<CategoryWhereInput>,
  orderBy?: Maybe<CategoryOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type AssetCircleImageCategoryArgs = {
  where?: Maybe<CategoryWhereInput>,
  orderBy?: Maybe<CategoryOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type AssetLabelImageCategoryArgs = {
  where?: Maybe<CategoryWhereInput>,
  orderBy?: Maybe<CategoryOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type AssetUrlArgs = {
  transformation?: Maybe<AssetTransformationInput>
};

/** A connection to a list of items. */
export type AssetConnection = {
   __typename?: 'AssetConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<AssetEdge>>,
  aggregate: AggregateAsset,
};

export type AssetCreateInput = {
  status?: Maybe<Status>,
  handle: Scalars['String'],
  fileName: Scalars['String'],
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  imageProduct?: Maybe<ProductCreateManyWithoutImageInput>,
  signImageCategory?: Maybe<CategoryCreateManyWithoutSignImageInput>,
  circleImageCategory?: Maybe<CategoryCreateManyWithoutCircleImageInput>,
  labelImageCategory?: Maybe<CategoryCreateManyWithoutLabelImageInput>,
};

export type AssetCreateOneWithoutCircleImageCategoryInput = {
  upload?: Maybe<AssetUploadWithoutCircleImageCategoryInput>,
  create?: Maybe<AssetCreateWithoutCircleImageCategoryInput>,
  connect?: Maybe<AssetWhereUniqueInput>,
};

export type AssetCreateOneWithoutImageProductInput = {
  upload?: Maybe<AssetUploadWithoutImageProductInput>,
  create?: Maybe<AssetCreateWithoutImageProductInput>,
  connect?: Maybe<AssetWhereUniqueInput>,
};

export type AssetCreateOneWithoutLabelImageCategoryInput = {
  upload?: Maybe<AssetUploadWithoutLabelImageCategoryInput>,
  create?: Maybe<AssetCreateWithoutLabelImageCategoryInput>,
  connect?: Maybe<AssetWhereUniqueInput>,
};

export type AssetCreateOneWithoutSignImageCategoryInput = {
  upload?: Maybe<AssetUploadWithoutSignImageCategoryInput>,
  create?: Maybe<AssetCreateWithoutSignImageCategoryInput>,
  connect?: Maybe<AssetWhereUniqueInput>,
};

export type AssetCreateWithoutCircleImageCategoryInput = {
  status?: Maybe<Status>,
  handle: Scalars['String'],
  fileName: Scalars['String'],
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  imageProduct?: Maybe<ProductCreateManyWithoutImageInput>,
  signImageCategory?: Maybe<CategoryCreateManyWithoutSignImageInput>,
  labelImageCategory?: Maybe<CategoryCreateManyWithoutLabelImageInput>,
};

export type AssetCreateWithoutImageProductInput = {
  status?: Maybe<Status>,
  handle: Scalars['String'],
  fileName: Scalars['String'],
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  signImageCategory?: Maybe<CategoryCreateManyWithoutSignImageInput>,
  circleImageCategory?: Maybe<CategoryCreateManyWithoutCircleImageInput>,
  labelImageCategory?: Maybe<CategoryCreateManyWithoutLabelImageInput>,
};

export type AssetCreateWithoutLabelImageCategoryInput = {
  status?: Maybe<Status>,
  handle: Scalars['String'],
  fileName: Scalars['String'],
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  imageProduct?: Maybe<ProductCreateManyWithoutImageInput>,
  signImageCategory?: Maybe<CategoryCreateManyWithoutSignImageInput>,
  circleImageCategory?: Maybe<CategoryCreateManyWithoutCircleImageInput>,
};

export type AssetCreateWithoutSignImageCategoryInput = {
  status?: Maybe<Status>,
  handle: Scalars['String'],
  fileName: Scalars['String'],
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  imageProduct?: Maybe<ProductCreateManyWithoutImageInput>,
  circleImageCategory?: Maybe<CategoryCreateManyWithoutCircleImageInput>,
  labelImageCategory?: Maybe<CategoryCreateManyWithoutLabelImageInput>,
};

/** An edge in a connection. */
export type AssetEdge = {
   __typename?: 'AssetEdge',
  /** The item at the end of the edge. */
  node: Asset,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum AssetOrderByInput {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC'
}

export type AssetPreviousValues = {
   __typename?: 'AssetPreviousValues',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  handle: Scalars['String'],
  fileName: Scalars['String'],
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
};

export type AssetSubscriptionPayload = {
   __typename?: 'AssetSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Asset>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<AssetPreviousValues>,
};

export type AssetSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AssetSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AssetSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AssetSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<AssetWhereInput>,
};

/** Transformations for Assets */
export type AssetTransformationInput = {
  image?: Maybe<ImageTransformationInput>,
  document?: Maybe<DocumentTransformationInput>,
  /** Pass `true` if you want to validate the passed transformation parameters */
  validateOptions?: Maybe<Scalars['Boolean']>,
};

export type AssetUpdateInput = {
  status?: Maybe<Status>,
  handle?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  imageProduct?: Maybe<ProductUpdateManyWithoutImageInput>,
  signImageCategory?: Maybe<CategoryUpdateManyWithoutSignImageInput>,
  circleImageCategory?: Maybe<CategoryUpdateManyWithoutCircleImageInput>,
  labelImageCategory?: Maybe<CategoryUpdateManyWithoutLabelImageInput>,
};

export type AssetUpdateManyMutationInput = {
  status?: Maybe<Status>,
  handle?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
};

export type AssetUpdateOneWithoutCircleImageCategoryInput = {
  create?: Maybe<AssetCreateWithoutCircleImageCategoryInput>,
  connect?: Maybe<AssetWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<AssetUpdateWithoutCircleImageCategoryDataInput>,
  upsert?: Maybe<AssetUpsertWithoutCircleImageCategoryInput>,
};

export type AssetUpdateOneWithoutImageProductInput = {
  create?: Maybe<AssetCreateWithoutImageProductInput>,
  connect?: Maybe<AssetWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<AssetUpdateWithoutImageProductDataInput>,
  upsert?: Maybe<AssetUpsertWithoutImageProductInput>,
};

export type AssetUpdateOneWithoutLabelImageCategoryInput = {
  create?: Maybe<AssetCreateWithoutLabelImageCategoryInput>,
  connect?: Maybe<AssetWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<AssetUpdateWithoutLabelImageCategoryDataInput>,
  upsert?: Maybe<AssetUpsertWithoutLabelImageCategoryInput>,
};

export type AssetUpdateOneWithoutSignImageCategoryInput = {
  create?: Maybe<AssetCreateWithoutSignImageCategoryInput>,
  connect?: Maybe<AssetWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<AssetUpdateWithoutSignImageCategoryDataInput>,
  upsert?: Maybe<AssetUpsertWithoutSignImageCategoryInput>,
};

export type AssetUpdateWithoutCircleImageCategoryDataInput = {
  status?: Maybe<Status>,
  handle?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  imageProduct?: Maybe<ProductUpdateManyWithoutImageInput>,
  signImageCategory?: Maybe<CategoryUpdateManyWithoutSignImageInput>,
  labelImageCategory?: Maybe<CategoryUpdateManyWithoutLabelImageInput>,
};

export type AssetUpdateWithoutImageProductDataInput = {
  status?: Maybe<Status>,
  handle?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  signImageCategory?: Maybe<CategoryUpdateManyWithoutSignImageInput>,
  circleImageCategory?: Maybe<CategoryUpdateManyWithoutCircleImageInput>,
  labelImageCategory?: Maybe<CategoryUpdateManyWithoutLabelImageInput>,
};

export type AssetUpdateWithoutLabelImageCategoryDataInput = {
  status?: Maybe<Status>,
  handle?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  imageProduct?: Maybe<ProductUpdateManyWithoutImageInput>,
  signImageCategory?: Maybe<CategoryUpdateManyWithoutSignImageInput>,
  circleImageCategory?: Maybe<CategoryUpdateManyWithoutCircleImageInput>,
};

export type AssetUpdateWithoutSignImageCategoryDataInput = {
  status?: Maybe<Status>,
  handle?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  imageProduct?: Maybe<ProductUpdateManyWithoutImageInput>,
  circleImageCategory?: Maybe<CategoryUpdateManyWithoutCircleImageInput>,
  labelImageCategory?: Maybe<CategoryUpdateManyWithoutLabelImageInput>,
};

export type AssetUploadInput = {
  url: Scalars['String'],
  status?: Maybe<Status>,
  imageProduct?: Maybe<ProductCreateManyWithoutImageInput>,
  signImageCategory?: Maybe<CategoryCreateManyWithoutSignImageInput>,
  circleImageCategory?: Maybe<CategoryCreateManyWithoutCircleImageInput>,
  labelImageCategory?: Maybe<CategoryCreateManyWithoutLabelImageInput>,
};

export type AssetUploadWithoutCircleImageCategoryInput = {
  url: Scalars['String'],
  status?: Maybe<Status>,
  imageProduct?: Maybe<ProductCreateManyWithoutImageInput>,
  signImageCategory?: Maybe<CategoryCreateManyWithoutSignImageInput>,
  labelImageCategory?: Maybe<CategoryCreateManyWithoutLabelImageInput>,
};

export type AssetUploadWithoutImageProductInput = {
  url: Scalars['String'],
  status?: Maybe<Status>,
  signImageCategory?: Maybe<CategoryCreateManyWithoutSignImageInput>,
  circleImageCategory?: Maybe<CategoryCreateManyWithoutCircleImageInput>,
  labelImageCategory?: Maybe<CategoryCreateManyWithoutLabelImageInput>,
};

export type AssetUploadWithoutLabelImageCategoryInput = {
  url: Scalars['String'],
  status?: Maybe<Status>,
  imageProduct?: Maybe<ProductCreateManyWithoutImageInput>,
  signImageCategory?: Maybe<CategoryCreateManyWithoutSignImageInput>,
  circleImageCategory?: Maybe<CategoryCreateManyWithoutCircleImageInput>,
};

export type AssetUploadWithoutSignImageCategoryInput = {
  url: Scalars['String'],
  status?: Maybe<Status>,
  imageProduct?: Maybe<ProductCreateManyWithoutImageInput>,
  circleImageCategory?: Maybe<CategoryCreateManyWithoutCircleImageInput>,
  labelImageCategory?: Maybe<CategoryCreateManyWithoutLabelImageInput>,
};

export type AssetUpsertWithoutCircleImageCategoryInput = {
  update: AssetUpdateWithoutCircleImageCategoryDataInput,
  create: AssetCreateWithoutCircleImageCategoryInput,
};

export type AssetUpsertWithoutImageProductInput = {
  update: AssetUpdateWithoutImageProductDataInput,
  create: AssetCreateWithoutImageProductInput,
};

export type AssetUpsertWithoutLabelImageCategoryInput = {
  update: AssetUpdateWithoutLabelImageCategoryDataInput,
  create: AssetCreateWithoutLabelImageCategoryInput,
};

export type AssetUpsertWithoutSignImageCategoryInput = {
  update: AssetUpdateWithoutSignImageCategoryDataInput,
  create: AssetCreateWithoutSignImageCategoryInput,
};

export type AssetWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AssetWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AssetWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AssetWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  handle?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  handle_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  handle_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  handle_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  handle_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  handle_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  handle_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  handle_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  handle_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  handle_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  handle_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  handle_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  handle_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  handle_not_ends_with?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  fileName_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  fileName_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  fileName_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  fileName_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  fileName_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  fileName_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  fileName_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  fileName_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  fileName_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  fileName_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  fileName_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  fileName_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  fileName_not_ends_with?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  height_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  height_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  height_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  height_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  height_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  height_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  height_gte?: Maybe<Scalars['Float']>,
  width?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  width_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  width_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  width_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  width_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  width_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  width_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  width_gte?: Maybe<Scalars['Float']>,
  size?: Maybe<Scalars['Float']>,
  /** All values that are not equal to given value. */
  size_not?: Maybe<Scalars['Float']>,
  /** All values that are contained in given list. */
  size_in?: Maybe<Array<Scalars['Float']>>,
  /** All values that are not contained in given list. */
  size_not_in?: Maybe<Array<Scalars['Float']>>,
  /** All values less than the given value. */
  size_lt?: Maybe<Scalars['Float']>,
  /** All values less than or equal the given value. */
  size_lte?: Maybe<Scalars['Float']>,
  /** All values greater than the given value. */
  size_gt?: Maybe<Scalars['Float']>,
  /** All values greater than or equal the given value. */
  size_gte?: Maybe<Scalars['Float']>,
  mimeType?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  mimeType_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  mimeType_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  mimeType_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  mimeType_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  mimeType_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  mimeType_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  mimeType_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  mimeType_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  mimeType_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  mimeType_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  mimeType_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  mimeType_not_ends_with?: Maybe<Scalars['String']>,
  imageProduct_every?: Maybe<ProductWhereInput>,
  imageProduct_some?: Maybe<ProductWhereInput>,
  imageProduct_none?: Maybe<ProductWhereInput>,
  signImageCategory_every?: Maybe<CategoryWhereInput>,
  signImageCategory_some?: Maybe<CategoryWhereInput>,
  signImageCategory_none?: Maybe<CategoryWhereInput>,
  circleImageCategory_every?: Maybe<CategoryWhereInput>,
  circleImageCategory_some?: Maybe<CategoryWhereInput>,
  circleImageCategory_none?: Maybe<CategoryWhereInput>,
  labelImageCategory_every?: Maybe<CategoryWhereInput>,
  labelImageCategory_some?: Maybe<CategoryWhereInput>,
  labelImageCategory_none?: Maybe<CategoryWhereInput>,
};

export type AssetWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  handle?: Maybe<Scalars['String']>,
};

export type BatchPayload = {
   __typename?: 'BatchPayload',
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long'],
};

export type Category = Node & {
   __typename?: 'Category',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  name: Scalars['String'],
  products?: Maybe<Array<Product>>,
  color: Color,
  signImage?: Maybe<Asset>,
  circleImage?: Maybe<Asset>,
  labelImage?: Maybe<Asset>,
  order: Scalars['Int'],
};


export type CategoryProductsArgs = {
  where?: Maybe<ProductWhereInput>,
  orderBy?: Maybe<ProductOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

/** A connection to a list of items. */
export type CategoryConnection = {
   __typename?: 'CategoryConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<CategoryEdge>>,
  aggregate: AggregateCategory,
};

export type CategoryCreateInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  color: ColorInput,
  order: Scalars['Int'],
  products?: Maybe<ProductCreateManyWithoutCategoryInput>,
  signImage?: Maybe<AssetCreateOneWithoutSignImageCategoryInput>,
  circleImage?: Maybe<AssetCreateOneWithoutCircleImageCategoryInput>,
  labelImage?: Maybe<AssetCreateOneWithoutLabelImageCategoryInput>,
};

export type CategoryCreateManyWithoutCircleImageInput = {
  create?: Maybe<Array<CategoryCreateWithoutCircleImageInput>>,
  connect?: Maybe<Array<CategoryWhereUniqueInput>>,
};

export type CategoryCreateManyWithoutLabelImageInput = {
  create?: Maybe<Array<CategoryCreateWithoutLabelImageInput>>,
  connect?: Maybe<Array<CategoryWhereUniqueInput>>,
};

export type CategoryCreateManyWithoutSignImageInput = {
  create?: Maybe<Array<CategoryCreateWithoutSignImageInput>>,
  connect?: Maybe<Array<CategoryWhereUniqueInput>>,
};

export type CategoryCreateOneWithoutProductsInput = {
  create?: Maybe<CategoryCreateWithoutProductsInput>,
  connect?: Maybe<CategoryWhereUniqueInput>,
};

export type CategoryCreateWithoutCircleImageInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  color: ColorInput,
  order: Scalars['Int'],
  products?: Maybe<ProductCreateManyWithoutCategoryInput>,
  signImage?: Maybe<AssetCreateOneWithoutSignImageCategoryInput>,
  labelImage?: Maybe<AssetCreateOneWithoutLabelImageCategoryInput>,
};

export type CategoryCreateWithoutLabelImageInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  color: ColorInput,
  order: Scalars['Int'],
  products?: Maybe<ProductCreateManyWithoutCategoryInput>,
  signImage?: Maybe<AssetCreateOneWithoutSignImageCategoryInput>,
  circleImage?: Maybe<AssetCreateOneWithoutCircleImageCategoryInput>,
};

export type CategoryCreateWithoutProductsInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  color: ColorInput,
  order: Scalars['Int'],
  signImage?: Maybe<AssetCreateOneWithoutSignImageCategoryInput>,
  circleImage?: Maybe<AssetCreateOneWithoutCircleImageCategoryInput>,
  labelImage?: Maybe<AssetCreateOneWithoutLabelImageCategoryInput>,
};

export type CategoryCreateWithoutSignImageInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  color: ColorInput,
  order: Scalars['Int'],
  products?: Maybe<ProductCreateManyWithoutCategoryInput>,
  circleImage?: Maybe<AssetCreateOneWithoutCircleImageCategoryInput>,
  labelImage?: Maybe<AssetCreateOneWithoutLabelImageCategoryInput>,
};

/** An edge in a connection. */
export type CategoryEdge = {
   __typename?: 'CategoryEdge',
  /** The item at the end of the edge. */
  node: Category,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum CategoryOrderByInput {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC'
}

export type CategoryPreviousValues = {
   __typename?: 'CategoryPreviousValues',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  name: Scalars['String'],
  color: Color,
  order: Scalars['Int'],
};

export type CategoryScalarWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CategoryScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CategoryScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CategoryScalarWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  color_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  color_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  color_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  color_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  color_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  color_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  color_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  color_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  color_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  color_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  color_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  color_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  color_not_ends_with?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  /** All values that are not equal to given value. */
  order_not?: Maybe<Scalars['Int']>,
  /** All values that are contained in given list. */
  order_in?: Maybe<Array<Scalars['Int']>>,
  /** All values that are not contained in given list. */
  order_not_in?: Maybe<Array<Scalars['Int']>>,
  /** All values less than the given value. */
  order_lt?: Maybe<Scalars['Int']>,
  /** All values less than or equal the given value. */
  order_lte?: Maybe<Scalars['Int']>,
  /** All values greater than the given value. */
  order_gt?: Maybe<Scalars['Int']>,
  /** All values greater than or equal the given value. */
  order_gte?: Maybe<Scalars['Int']>,
};

export type CategorySubscriptionPayload = {
   __typename?: 'CategorySubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Category>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<CategoryPreviousValues>,
};

export type CategorySubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CategorySubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CategorySubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CategorySubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<CategoryWhereInput>,
};

export type CategoryUpdateInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  color?: Maybe<ColorInput>,
  order?: Maybe<Scalars['Int']>,
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>,
  signImage?: Maybe<AssetUpdateOneWithoutSignImageCategoryInput>,
  circleImage?: Maybe<AssetUpdateOneWithoutCircleImageCategoryInput>,
  labelImage?: Maybe<AssetUpdateOneWithoutLabelImageCategoryInput>,
};

export type CategoryUpdateManyDataInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  color?: Maybe<ColorInput>,
  order?: Maybe<Scalars['Int']>,
};

export type CategoryUpdateManyMutationInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  color?: Maybe<ColorInput>,
  order?: Maybe<Scalars['Int']>,
};

export type CategoryUpdateManyWithoutCircleImageInput = {
  create?: Maybe<Array<CategoryCreateWithoutCircleImageInput>>,
  connect?: Maybe<Array<CategoryWhereUniqueInput>>,
  set?: Maybe<Array<CategoryWhereUniqueInput>>,
  disconnect?: Maybe<Array<CategoryWhereUniqueInput>>,
  delete?: Maybe<Array<CategoryWhereUniqueInput>>,
  update?: Maybe<Array<CategoryUpdateWithWhereUniqueWithoutCircleImageInput>>,
  updateMany?: Maybe<Array<CategoryUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CategoryScalarWhereInput>>,
  upsert?: Maybe<Array<CategoryUpsertWithWhereUniqueWithoutCircleImageInput>>,
};

export type CategoryUpdateManyWithoutLabelImageInput = {
  create?: Maybe<Array<CategoryCreateWithoutLabelImageInput>>,
  connect?: Maybe<Array<CategoryWhereUniqueInput>>,
  set?: Maybe<Array<CategoryWhereUniqueInput>>,
  disconnect?: Maybe<Array<CategoryWhereUniqueInput>>,
  delete?: Maybe<Array<CategoryWhereUniqueInput>>,
  update?: Maybe<Array<CategoryUpdateWithWhereUniqueWithoutLabelImageInput>>,
  updateMany?: Maybe<Array<CategoryUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CategoryScalarWhereInput>>,
  upsert?: Maybe<Array<CategoryUpsertWithWhereUniqueWithoutLabelImageInput>>,
};

export type CategoryUpdateManyWithoutSignImageInput = {
  create?: Maybe<Array<CategoryCreateWithoutSignImageInput>>,
  connect?: Maybe<Array<CategoryWhereUniqueInput>>,
  set?: Maybe<Array<CategoryWhereUniqueInput>>,
  disconnect?: Maybe<Array<CategoryWhereUniqueInput>>,
  delete?: Maybe<Array<CategoryWhereUniqueInput>>,
  update?: Maybe<Array<CategoryUpdateWithWhereUniqueWithoutSignImageInput>>,
  updateMany?: Maybe<Array<CategoryUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<CategoryScalarWhereInput>>,
  upsert?: Maybe<Array<CategoryUpsertWithWhereUniqueWithoutSignImageInput>>,
};

export type CategoryUpdateManyWithWhereNestedInput = {
  where: CategoryScalarWhereInput,
  data: CategoryUpdateManyDataInput,
};

export type CategoryUpdateOneWithoutProductsInput = {
  create?: Maybe<CategoryCreateWithoutProductsInput>,
  connect?: Maybe<CategoryWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<CategoryUpdateWithoutProductsDataInput>,
  upsert?: Maybe<CategoryUpsertWithoutProductsInput>,
};

export type CategoryUpdateWithoutCircleImageDataInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  color?: Maybe<ColorInput>,
  order?: Maybe<Scalars['Int']>,
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>,
  signImage?: Maybe<AssetUpdateOneWithoutSignImageCategoryInput>,
  labelImage?: Maybe<AssetUpdateOneWithoutLabelImageCategoryInput>,
};

export type CategoryUpdateWithoutLabelImageDataInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  color?: Maybe<ColorInput>,
  order?: Maybe<Scalars['Int']>,
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>,
  signImage?: Maybe<AssetUpdateOneWithoutSignImageCategoryInput>,
  circleImage?: Maybe<AssetUpdateOneWithoutCircleImageCategoryInput>,
};

export type CategoryUpdateWithoutProductsDataInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  color?: Maybe<ColorInput>,
  order?: Maybe<Scalars['Int']>,
  signImage?: Maybe<AssetUpdateOneWithoutSignImageCategoryInput>,
  circleImage?: Maybe<AssetUpdateOneWithoutCircleImageCategoryInput>,
  labelImage?: Maybe<AssetUpdateOneWithoutLabelImageCategoryInput>,
};

export type CategoryUpdateWithoutSignImageDataInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  color?: Maybe<ColorInput>,
  order?: Maybe<Scalars['Int']>,
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>,
  circleImage?: Maybe<AssetUpdateOneWithoutCircleImageCategoryInput>,
  labelImage?: Maybe<AssetUpdateOneWithoutLabelImageCategoryInput>,
};

export type CategoryUpdateWithWhereUniqueWithoutCircleImageInput = {
  where: CategoryWhereUniqueInput,
  data: CategoryUpdateWithoutCircleImageDataInput,
};

export type CategoryUpdateWithWhereUniqueWithoutLabelImageInput = {
  where: CategoryWhereUniqueInput,
  data: CategoryUpdateWithoutLabelImageDataInput,
};

export type CategoryUpdateWithWhereUniqueWithoutSignImageInput = {
  where: CategoryWhereUniqueInput,
  data: CategoryUpdateWithoutSignImageDataInput,
};

export type CategoryUpsertWithoutProductsInput = {
  update: CategoryUpdateWithoutProductsDataInput,
  create: CategoryCreateWithoutProductsInput,
};

export type CategoryUpsertWithWhereUniqueWithoutCircleImageInput = {
  where: CategoryWhereUniqueInput,
  update: CategoryUpdateWithoutCircleImageDataInput,
  create: CategoryCreateWithoutCircleImageInput,
};

export type CategoryUpsertWithWhereUniqueWithoutLabelImageInput = {
  where: CategoryWhereUniqueInput,
  update: CategoryUpdateWithoutLabelImageDataInput,
  create: CategoryCreateWithoutLabelImageInput,
};

export type CategoryUpsertWithWhereUniqueWithoutSignImageInput = {
  where: CategoryWhereUniqueInput,
  update: CategoryUpdateWithoutSignImageDataInput,
  create: CategoryCreateWithoutSignImageInput,
};

export type CategoryWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CategoryWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CategoryWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CategoryWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  color?: Maybe<ColorInput>,
  /** All values that are not equal to given value. */
  color_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  color_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  color_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  color_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  color_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  color_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  color_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  color_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  color_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  color_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  color_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  color_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  color_not_ends_with?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  /** All values that are not equal to given value. */
  order_not?: Maybe<Scalars['Int']>,
  /** All values that are contained in given list. */
  order_in?: Maybe<Array<Scalars['Int']>>,
  /** All values that are not contained in given list. */
  order_not_in?: Maybe<Array<Scalars['Int']>>,
  /** All values less than the given value. */
  order_lt?: Maybe<Scalars['Int']>,
  /** All values less than or equal the given value. */
  order_lte?: Maybe<Scalars['Int']>,
  /** All values greater than the given value. */
  order_gt?: Maybe<Scalars['Int']>,
  /** All values greater than or equal the given value. */
  order_gte?: Maybe<Scalars['Int']>,
  products_every?: Maybe<ProductWhereInput>,
  products_some?: Maybe<ProductWhereInput>,
  products_none?: Maybe<ProductWhereInput>,
  signImage?: Maybe<AssetWhereInput>,
  circleImage?: Maybe<AssetWhereInput>,
  labelImage?: Maybe<AssetWhereInput>,
};

export type CategoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
   __typename?: 'Color',
  hex: Scalars['HEX'],
  rgba: Rgba,
  css: Scalars['String'],
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: Maybe<Scalars['HEX']>,
  rgba?: Maybe<RgbaInput>,
};

export type CompleteContent = Node & {
   __typename?: 'CompleteContent',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  content?: Maybe<RichText>,
  underSocialContent?: Maybe<RichText>,
  title?: Maybe<Scalars['String']>,
};

/** A connection to a list of items. */
export type CompleteContentConnection = {
   __typename?: 'CompleteContentConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<CompleteContentEdge>>,
  aggregate: AggregateCompleteContent,
};

export type CompleteContentCreateInput = {
  status?: Maybe<Status>,
  content?: Maybe<Scalars['RichTextAST']>,
  underSocialContent?: Maybe<Scalars['RichTextAST']>,
  title?: Maybe<Scalars['String']>,
};

/** An edge in a connection. */
export type CompleteContentEdge = {
   __typename?: 'CompleteContentEdge',
  /** The item at the end of the edge. */
  node: CompleteContent,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum CompleteContentOrderByInput {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  UnderSocialContentAsc = 'underSocialContent_ASC',
  UnderSocialContentDesc = 'underSocialContent_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type CompleteContentPreviousValues = {
   __typename?: 'CompleteContentPreviousValues',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  content?: Maybe<RichText>,
  underSocialContent?: Maybe<RichText>,
  title?: Maybe<Scalars['String']>,
};

export type CompleteContentSubscriptionPayload = {
   __typename?: 'CompleteContentSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<CompleteContent>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<CompleteContentPreviousValues>,
};

export type CompleteContentSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CompleteContentSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CompleteContentSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CompleteContentSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<CompleteContentWhereInput>,
};

export type CompleteContentUpdateInput = {
  status?: Maybe<Status>,
  content?: Maybe<Scalars['RichTextAST']>,
  underSocialContent?: Maybe<Scalars['RichTextAST']>,
  title?: Maybe<Scalars['String']>,
};

export type CompleteContentUpdateManyMutationInput = {
  status?: Maybe<Status>,
  content?: Maybe<Scalars['RichTextAST']>,
  underSocialContent?: Maybe<Scalars['RichTextAST']>,
  title?: Maybe<Scalars['String']>,
};

export type CompleteContentWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CompleteContentWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CompleteContentWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CompleteContentWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  title_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  title_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  title_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  title_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  title_not_ends_with?: Maybe<Scalars['String']>,
};

export type CompleteContentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};


export enum DocumentFileTypes {
  Jpg = 'jpg',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  Png = 'png',
  Svg = 'svg',
  Txt = 'txt',
  Webp = 'webp',
  Docx = 'docx',
  Html = 'html',
  Pdf = 'pdf',
  Doc = 'doc',
  Xlsx = 'xlsx',
  Xls = 'xls',
  Pptx = 'pptx',
  Ppt = 'ppt'
}

export type DocumentOutputInput = {
  /** 
 * Transforms a document into a desired file type.
   * See this matrix for format support:
   * 
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
 **/
  format?: Maybe<DocumentFileTypes>,
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: Maybe<DocumentOutputInput>,
};

export type EntryForm = Node & {
   __typename?: 'EntryForm',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  products?: Maybe<Array<Product>>,
  city?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
};


export type EntryFormProductsArgs = {
  where?: Maybe<ProductWhereInput>,
  orderBy?: Maybe<ProductOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

/** A connection to a list of items. */
export type EntryFormConnection = {
   __typename?: 'EntryFormConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<EntryFormEdge>>,
  aggregate: AggregateEntryForm,
};

export type EntryFormContent = Node & {
   __typename?: 'EntryFormContent',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  content?: Maybe<RichText>,
  title?: Maybe<Scalars['String']>,
  terms?: Maybe<RichText>,
};

/** A connection to a list of items. */
export type EntryFormContentConnection = {
   __typename?: 'EntryFormContentConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<EntryFormContentEdge>>,
  aggregate: AggregateEntryFormContent,
};

export type EntryFormContentCreateInput = {
  status?: Maybe<Status>,
  content?: Maybe<Scalars['RichTextAST']>,
  title?: Maybe<Scalars['String']>,
  terms?: Maybe<Scalars['RichTextAST']>,
};

/** An edge in a connection. */
export type EntryFormContentEdge = {
   __typename?: 'EntryFormContentEdge',
  /** The item at the end of the edge. */
  node: EntryFormContent,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum EntryFormContentOrderByInput {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TermsAsc = 'terms_ASC',
  TermsDesc = 'terms_DESC'
}

export type EntryFormContentPreviousValues = {
   __typename?: 'EntryFormContentPreviousValues',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  content?: Maybe<RichText>,
  title?: Maybe<Scalars['String']>,
  terms?: Maybe<RichText>,
};

export type EntryFormContentSubscriptionPayload = {
   __typename?: 'EntryFormContentSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<EntryFormContent>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<EntryFormContentPreviousValues>,
};

export type EntryFormContentSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<EntryFormContentSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<EntryFormContentSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<EntryFormContentSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<EntryFormContentWhereInput>,
};

export type EntryFormContentUpdateInput = {
  status?: Maybe<Status>,
  content?: Maybe<Scalars['RichTextAST']>,
  title?: Maybe<Scalars['String']>,
  terms?: Maybe<Scalars['RichTextAST']>,
};

export type EntryFormContentUpdateManyMutationInput = {
  status?: Maybe<Status>,
  content?: Maybe<Scalars['RichTextAST']>,
  title?: Maybe<Scalars['String']>,
  terms?: Maybe<Scalars['RichTextAST']>,
};

export type EntryFormContentWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<EntryFormContentWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<EntryFormContentWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<EntryFormContentWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  title_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  title_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  title_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  title_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  title_not_ends_with?: Maybe<Scalars['String']>,
};

export type EntryFormContentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type EntryFormCreateInput = {
  status?: Maybe<Status>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
  products?: Maybe<ProductCreateManyWithoutEntryFormsInput>,
};

export type EntryFormCreateManyWithoutProductsInput = {
  create?: Maybe<Array<EntryFormCreateWithoutProductsInput>>,
  connect?: Maybe<Array<EntryFormWhereUniqueInput>>,
};

export type EntryFormCreateWithoutProductsInput = {
  status?: Maybe<Status>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
};

/** An edge in a connection. */
export type EntryFormEdge = {
   __typename?: 'EntryFormEdge',
  /** The item at the end of the edge. */
  node: EntryForm,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum EntryFormOrderByInput {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  OptinAsc = 'optin_ASC',
  OptinDesc = 'optin_DESC'
}

export type EntryFormPreviousValues = {
   __typename?: 'EntryFormPreviousValues',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
};

export type EntryFormScalarWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<EntryFormScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<EntryFormScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<EntryFormScalarWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  firstName?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  firstName_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  firstName_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  firstName_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  firstName_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  firstName_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  firstName_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  firstName_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  firstName_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  firstName_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  firstName_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  firstName_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  firstName_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  firstName_not_ends_with?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  lastName_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  lastName_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  lastName_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  lastName_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  lastName_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  lastName_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  lastName_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  lastName_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  lastName_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  lastName_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  lastName_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  lastName_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  lastName_not_ends_with?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  email_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  email_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  email_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  email_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  email_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  email_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  email_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  email_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  email_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  email_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  email_not_ends_with?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  phone_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  phone_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  phone_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  phone_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  phone_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  phone_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  phone_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  phone_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  phone_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  phone_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  phone_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  phone_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  phone_not_ends_with?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  city_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  city_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  city_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  city_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  city_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  city_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  city_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  city_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  city_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  city_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  city_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  city_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  city_not_ends_with?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  optin_not?: Maybe<Scalars['Boolean']>,
};

export type EntryFormSubscriptionPayload = {
   __typename?: 'EntryFormSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<EntryForm>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<EntryFormPreviousValues>,
};

export type EntryFormSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<EntryFormSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<EntryFormSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<EntryFormSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<EntryFormWhereInput>,
};

export type EntryFormUpdateInput = {
  status?: Maybe<Status>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
  products?: Maybe<ProductUpdateManyWithoutEntryFormsInput>,
};

export type EntryFormUpdateManyDataInput = {
  status?: Maybe<Status>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
};

export type EntryFormUpdateManyMutationInput = {
  status?: Maybe<Status>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
};

export type EntryFormUpdateManyWithoutProductsInput = {
  create?: Maybe<Array<EntryFormCreateWithoutProductsInput>>,
  connect?: Maybe<Array<EntryFormWhereUniqueInput>>,
  set?: Maybe<Array<EntryFormWhereUniqueInput>>,
  disconnect?: Maybe<Array<EntryFormWhereUniqueInput>>,
  delete?: Maybe<Array<EntryFormWhereUniqueInput>>,
  update?: Maybe<Array<EntryFormUpdateWithWhereUniqueWithoutProductsInput>>,
  updateMany?: Maybe<Array<EntryFormUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<EntryFormScalarWhereInput>>,
  upsert?: Maybe<Array<EntryFormUpsertWithWhereUniqueWithoutProductsInput>>,
};

export type EntryFormUpdateManyWithWhereNestedInput = {
  where: EntryFormScalarWhereInput,
  data: EntryFormUpdateManyDataInput,
};

export type EntryFormUpdateWithoutProductsDataInput = {
  status?: Maybe<Status>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
};

export type EntryFormUpdateWithWhereUniqueWithoutProductsInput = {
  where: EntryFormWhereUniqueInput,
  data: EntryFormUpdateWithoutProductsDataInput,
};

export type EntryFormUpsertWithWhereUniqueWithoutProductsInput = {
  where: EntryFormWhereUniqueInput,
  update: EntryFormUpdateWithoutProductsDataInput,
  create: EntryFormCreateWithoutProductsInput,
};

export type EntryFormWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<EntryFormWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<EntryFormWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<EntryFormWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  firstName?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  firstName_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  firstName_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  firstName_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  firstName_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  firstName_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  firstName_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  firstName_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  firstName_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  firstName_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  firstName_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  firstName_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  firstName_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  firstName_not_ends_with?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  lastName_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  lastName_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  lastName_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  lastName_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  lastName_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  lastName_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  lastName_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  lastName_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  lastName_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  lastName_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  lastName_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  lastName_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  lastName_not_ends_with?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  email_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  email_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  email_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  email_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  email_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  email_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  email_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  email_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  email_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  email_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  email_not_ends_with?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  phone_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  phone_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  phone_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  phone_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  phone_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  phone_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  phone_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  phone_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  phone_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  phone_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  phone_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  phone_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  phone_not_ends_with?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  city_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  city_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  city_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  city_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  city_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  city_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  city_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  city_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  city_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  city_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  city_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  city_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  city_not_ends_with?: Maybe<Scalars['String']>,
  optin?: Maybe<Scalars['Boolean']>,
  /** All values that are not equal to given value. */
  optin_not?: Maybe<Scalars['Boolean']>,
  products_every?: Maybe<ProductWhereInput>,
  products_some?: Maybe<ProductWhereInput>,
  products_none?: Maybe<ProductWhereInput>,
};

export type EntryFormWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};


export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /** 
 * Resizes the image to fit the specified parameters exactly by removing any
   * parts of the image that don't fit within the boundaries.
 **/
  Crop = 'crop',
  /** 
 * Resizes the image to fit the specified parameters exactly by scaling the image
   * to the desired size. The aspect ratio of the image is not respected and the
   * image can be distorted using this method.
 **/
  Scale = 'scale',
  /** 
 * Resizes the image to fit within the parameters, but as opposed to 'fit:clip'
   * will not scale the image if the image is smaller than the output size.
 **/
  Max = 'max'
}

export type ImageResizeInput = {
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: Maybe<Scalars['Int']>,
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: Maybe<Scalars['Int']>,
  /** The default value for the fit parameter is fit:clip. */
  fit?: Maybe<ImageFit>,
};

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: Maybe<ImageResizeInput>,
};


export enum Locale {
  En = 'EN'
}


export type Mutation = {
   __typename?: 'Mutation',
  createAsset: Asset,
  uploadAsset: Asset,
  createCategory: Category,
  createProduct: Product,
  createEntryForm: EntryForm,
  createEntryFormContent: EntryFormContent,
  createCompleteContent: CompleteContent,
  createPopup: Popup,
  updateAsset?: Maybe<Asset>,
  updateCategory?: Maybe<Category>,
  updateProduct?: Maybe<Product>,
  updateEntryForm?: Maybe<EntryForm>,
  updateEntryFormContent?: Maybe<EntryFormContent>,
  updateCompleteContent?: Maybe<CompleteContent>,
  updatePopup?: Maybe<Popup>,
  deleteAsset?: Maybe<Asset>,
  deleteCategory?: Maybe<Category>,
  deleteProduct?: Maybe<Product>,
  deleteEntryForm?: Maybe<EntryForm>,
  deleteEntryFormContent?: Maybe<EntryFormContent>,
  deleteCompleteContent?: Maybe<CompleteContent>,
  deletePopup?: Maybe<Popup>,
  upsertAsset: Asset,
  upsertCategory: Category,
  upsertProduct: Product,
  upsertEntryForm: EntryForm,
  upsertEntryFormContent: EntryFormContent,
  upsertCompleteContent: CompleteContent,
  upsertPopup: Popup,
  updateManyAssets: BatchPayload,
  updateManyCategories: BatchPayload,
  updateManyProducts: BatchPayload,
  updateManyEntryForms: BatchPayload,
  updateManyEntryFormContents: BatchPayload,
  updateManyCompleteContents: BatchPayload,
  updateManyPopups: BatchPayload,
  deleteManyAssets: BatchPayload,
  deleteManyCategories: BatchPayload,
  deleteManyProducts: BatchPayload,
  deleteManyEntryForms: BatchPayload,
  deleteManyEntryFormContents: BatchPayload,
  deleteManyCompleteContents: BatchPayload,
  deleteManyPopups: BatchPayload,
};


export type MutationCreateAssetArgs = {
  data: AssetCreateInput
};


export type MutationUploadAssetArgs = {
  data: AssetUploadInput
};


export type MutationCreateCategoryArgs = {
  data: CategoryCreateInput
};


export type MutationCreateProductArgs = {
  data: ProductCreateInput
};


export type MutationCreateEntryFormArgs = {
  data: EntryFormCreateInput
};


export type MutationCreateEntryFormContentArgs = {
  data: EntryFormContentCreateInput
};


export type MutationCreateCompleteContentArgs = {
  data: CompleteContentCreateInput
};


export type MutationCreatePopupArgs = {
  data: PopupCreateInput
};


export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput,
  where: AssetWhereUniqueInput
};


export type MutationUpdateCategoryArgs = {
  data: CategoryUpdateInput,
  where: CategoryWhereUniqueInput
};


export type MutationUpdateProductArgs = {
  data: ProductUpdateInput,
  where: ProductWhereUniqueInput
};


export type MutationUpdateEntryFormArgs = {
  data: EntryFormUpdateInput,
  where: EntryFormWhereUniqueInput
};


export type MutationUpdateEntryFormContentArgs = {
  data: EntryFormContentUpdateInput,
  where: EntryFormContentWhereUniqueInput
};


export type MutationUpdateCompleteContentArgs = {
  data: CompleteContentUpdateInput,
  where: CompleteContentWhereUniqueInput
};


export type MutationUpdatePopupArgs = {
  data: PopupUpdateInput,
  where: PopupWhereUniqueInput
};


export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput
};


export type MutationDeleteCategoryArgs = {
  where: CategoryWhereUniqueInput
};


export type MutationDeleteProductArgs = {
  where: ProductWhereUniqueInput
};


export type MutationDeleteEntryFormArgs = {
  where: EntryFormWhereUniqueInput
};


export type MutationDeleteEntryFormContentArgs = {
  where: EntryFormContentWhereUniqueInput
};


export type MutationDeleteCompleteContentArgs = {
  where: CompleteContentWhereUniqueInput
};


export type MutationDeletePopupArgs = {
  where: PopupWhereUniqueInput
};


export type MutationUpsertAssetArgs = {
  where: AssetWhereUniqueInput,
  create: AssetCreateInput,
  update: AssetUpdateInput
};


export type MutationUpsertCategoryArgs = {
  where: CategoryWhereUniqueInput,
  create: CategoryCreateInput,
  update: CategoryUpdateInput
};


export type MutationUpsertProductArgs = {
  where: ProductWhereUniqueInput,
  create: ProductCreateInput,
  update: ProductUpdateInput
};


export type MutationUpsertEntryFormArgs = {
  where: EntryFormWhereUniqueInput,
  create: EntryFormCreateInput,
  update: EntryFormUpdateInput
};


export type MutationUpsertEntryFormContentArgs = {
  where: EntryFormContentWhereUniqueInput,
  create: EntryFormContentCreateInput,
  update: EntryFormContentUpdateInput
};


export type MutationUpsertCompleteContentArgs = {
  where: CompleteContentWhereUniqueInput,
  create: CompleteContentCreateInput,
  update: CompleteContentUpdateInput
};


export type MutationUpsertPopupArgs = {
  where: PopupWhereUniqueInput,
  create: PopupCreateInput,
  update: PopupUpdateInput
};


export type MutationUpdateManyAssetsArgs = {
  data: AssetUpdateManyMutationInput,
  where?: Maybe<AssetWhereInput>
};


export type MutationUpdateManyCategoriesArgs = {
  data: CategoryUpdateManyMutationInput,
  where?: Maybe<CategoryWhereInput>
};


export type MutationUpdateManyProductsArgs = {
  data: ProductUpdateManyMutationInput,
  where?: Maybe<ProductWhereInput>
};


export type MutationUpdateManyEntryFormsArgs = {
  data: EntryFormUpdateManyMutationInput,
  where?: Maybe<EntryFormWhereInput>
};


export type MutationUpdateManyEntryFormContentsArgs = {
  data: EntryFormContentUpdateManyMutationInput,
  where?: Maybe<EntryFormContentWhereInput>
};


export type MutationUpdateManyCompleteContentsArgs = {
  data: CompleteContentUpdateManyMutationInput,
  where?: Maybe<CompleteContentWhereInput>
};


export type MutationUpdateManyPopupsArgs = {
  data: PopupUpdateManyMutationInput,
  where?: Maybe<PopupWhereInput>
};


export type MutationDeleteManyAssetsArgs = {
  where?: Maybe<AssetWhereInput>
};


export type MutationDeleteManyCategoriesArgs = {
  where?: Maybe<CategoryWhereInput>
};


export type MutationDeleteManyProductsArgs = {
  where?: Maybe<ProductWhereInput>
};


export type MutationDeleteManyEntryFormsArgs = {
  where?: Maybe<EntryFormWhereInput>
};


export type MutationDeleteManyEntryFormContentsArgs = {
  where?: Maybe<EntryFormContentWhereInput>
};


export type MutationDeleteManyCompleteContentsArgs = {
  where?: Maybe<CompleteContentWhereInput>
};


export type MutationDeleteManyPopupsArgs = {
  where?: Maybe<PopupWhereInput>
};

export enum MutationType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED'
}

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'],
};

/** Information about pagination in a connection. */
export type PageInfo = {
   __typename?: 'PageInfo',
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'],
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'],
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>,
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>,
};

export type Popup = Node & {
   __typename?: 'Popup',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  name: Scalars['String'],
  content: RichText,
  terms?: Maybe<RichText>,
};

/** A connection to a list of items. */
export type PopupConnection = {
   __typename?: 'PopupConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<PopupEdge>>,
  aggregate: AggregatePopup,
};

export type PopupCreateInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  content: Scalars['RichTextAST'],
  terms?: Maybe<Scalars['RichTextAST']>,
};

/** An edge in a connection. */
export type PopupEdge = {
   __typename?: 'PopupEdge',
  /** The item at the end of the edge. */
  node: Popup,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum PopupOrderByInput {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  TermsAsc = 'terms_ASC',
  TermsDesc = 'terms_DESC'
}

export type PopupPreviousValues = {
   __typename?: 'PopupPreviousValues',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  name: Scalars['String'],
  content: RichText,
  terms?: Maybe<RichText>,
};

export type PopupSubscriptionPayload = {
   __typename?: 'PopupSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Popup>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<PopupPreviousValues>,
};

export type PopupSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PopupSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PopupSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PopupSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<PopupWhereInput>,
};

export type PopupUpdateInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['RichTextAST']>,
  terms?: Maybe<Scalars['RichTextAST']>,
};

export type PopupUpdateManyMutationInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['RichTextAST']>,
  terms?: Maybe<Scalars['RichTextAST']>,
};

export type PopupWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PopupWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PopupWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PopupWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
};

export type PopupWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
};

export type Product = Node & {
   __typename?: 'Product',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  name: Scalars['String'],
  image?: Maybe<Asset>,
  category?: Maybe<Category>,
  entryForms?: Maybe<Array<EntryForm>>,
};


export type ProductEntryFormsArgs = {
  where?: Maybe<EntryFormWhereInput>,
  orderBy?: Maybe<EntryFormOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

/** A connection to a list of items. */
export type ProductConnection = {
   __typename?: 'ProductConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges: Array<Maybe<ProductEdge>>,
  aggregate: AggregateProduct,
};

export type ProductCreateInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  image?: Maybe<AssetCreateOneWithoutImageProductInput>,
  category?: Maybe<CategoryCreateOneWithoutProductsInput>,
  entryForms?: Maybe<EntryFormCreateManyWithoutProductsInput>,
};

export type ProductCreateManyWithoutCategoryInput = {
  create?: Maybe<Array<ProductCreateWithoutCategoryInput>>,
  connect?: Maybe<Array<ProductWhereUniqueInput>>,
};

export type ProductCreateManyWithoutEntryFormsInput = {
  create?: Maybe<Array<ProductCreateWithoutEntryFormsInput>>,
  connect?: Maybe<Array<ProductWhereUniqueInput>>,
};

export type ProductCreateManyWithoutImageInput = {
  create?: Maybe<Array<ProductCreateWithoutImageInput>>,
  connect?: Maybe<Array<ProductWhereUniqueInput>>,
};

export type ProductCreateWithoutCategoryInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  image?: Maybe<AssetCreateOneWithoutImageProductInput>,
  entryForms?: Maybe<EntryFormCreateManyWithoutProductsInput>,
};

export type ProductCreateWithoutEntryFormsInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  image?: Maybe<AssetCreateOneWithoutImageProductInput>,
  category?: Maybe<CategoryCreateOneWithoutProductsInput>,
};

export type ProductCreateWithoutImageInput = {
  status?: Maybe<Status>,
  name: Scalars['String'],
  category?: Maybe<CategoryCreateOneWithoutProductsInput>,
  entryForms?: Maybe<EntryFormCreateManyWithoutProductsInput>,
};

/** An edge in a connection. */
export type ProductEdge = {
   __typename?: 'ProductEdge',
  /** The item at the end of the edge. */
  node: Product,
  /** A cursor for use in pagination. */
  cursor: Scalars['String'],
};

export enum ProductOrderByInput {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type ProductPreviousValues = {
   __typename?: 'ProductPreviousValues',
  status: Status,
  updatedAt: Scalars['DateTime'],
  createdAt: Scalars['DateTime'],
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ProductScalarWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProductScalarWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProductScalarWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProductScalarWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
};

export type ProductSubscriptionPayload = {
   __typename?: 'ProductSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Product>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<ProductPreviousValues>,
};

export type ProductSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProductSubscriptionWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProductSubscriptionWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProductSubscriptionWhereInput>>,
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>,
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>,
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<ProductWhereInput>,
};

export type ProductUpdateInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  image?: Maybe<AssetUpdateOneWithoutImageProductInput>,
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>,
  entryForms?: Maybe<EntryFormUpdateManyWithoutProductsInput>,
};

export type ProductUpdateManyDataInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
};

export type ProductUpdateManyMutationInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
};

export type ProductUpdateManyWithoutCategoryInput = {
  create?: Maybe<Array<ProductCreateWithoutCategoryInput>>,
  connect?: Maybe<Array<ProductWhereUniqueInput>>,
  set?: Maybe<Array<ProductWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>,
  delete?: Maybe<Array<ProductWhereUniqueInput>>,
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutCategoryInput>>,
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>,
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutCategoryInput>>,
};

export type ProductUpdateManyWithoutEntryFormsInput = {
  create?: Maybe<Array<ProductCreateWithoutEntryFormsInput>>,
  connect?: Maybe<Array<ProductWhereUniqueInput>>,
  set?: Maybe<Array<ProductWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>,
  delete?: Maybe<Array<ProductWhereUniqueInput>>,
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutEntryFormsInput>>,
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>,
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutEntryFormsInput>>,
};

export type ProductUpdateManyWithoutImageInput = {
  create?: Maybe<Array<ProductCreateWithoutImageInput>>,
  connect?: Maybe<Array<ProductWhereUniqueInput>>,
  set?: Maybe<Array<ProductWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>,
  delete?: Maybe<Array<ProductWhereUniqueInput>>,
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutImageInput>>,
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>,
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutImageInput>>,
};

export type ProductUpdateManyWithWhereNestedInput = {
  where: ProductScalarWhereInput,
  data: ProductUpdateManyDataInput,
};

export type ProductUpdateWithoutCategoryDataInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  image?: Maybe<AssetUpdateOneWithoutImageProductInput>,
  entryForms?: Maybe<EntryFormUpdateManyWithoutProductsInput>,
};

export type ProductUpdateWithoutEntryFormsDataInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  image?: Maybe<AssetUpdateOneWithoutImageProductInput>,
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>,
};

export type ProductUpdateWithoutImageDataInput = {
  status?: Maybe<Status>,
  name?: Maybe<Scalars['String']>,
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>,
  entryForms?: Maybe<EntryFormUpdateManyWithoutProductsInput>,
};

export type ProductUpdateWithWhereUniqueWithoutCategoryInput = {
  where: ProductWhereUniqueInput,
  data: ProductUpdateWithoutCategoryDataInput,
};

export type ProductUpdateWithWhereUniqueWithoutEntryFormsInput = {
  where: ProductWhereUniqueInput,
  data: ProductUpdateWithoutEntryFormsDataInput,
};

export type ProductUpdateWithWhereUniqueWithoutImageInput = {
  where: ProductWhereUniqueInput,
  data: ProductUpdateWithoutImageDataInput,
};

export type ProductUpsertWithWhereUniqueWithoutCategoryInput = {
  where: ProductWhereUniqueInput,
  update: ProductUpdateWithoutCategoryDataInput,
  create: ProductCreateWithoutCategoryInput,
};

export type ProductUpsertWithWhereUniqueWithoutEntryFormsInput = {
  where: ProductWhereUniqueInput,
  update: ProductUpdateWithoutEntryFormsDataInput,
  create: ProductCreateWithoutEntryFormsInput,
};

export type ProductUpsertWithWhereUniqueWithoutImageInput = {
  where: ProductWhereUniqueInput,
  update: ProductUpdateWithoutImageDataInput,
  create: ProductCreateWithoutImageInput,
};

export type ProductWhereInput = {
  _search?: Maybe<Scalars['String']>,
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProductWhereInput>>,
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProductWhereInput>>,
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProductWhereInput>>,
  status?: Maybe<Status>,
  /** All values that are not equal to given value. */
  status_not?: Maybe<Status>,
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Status>>,
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Status>>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>,
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>,
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>,
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>,
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>,
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>,
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>,
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>,
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>,
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>,
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>,
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>,
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>,
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>,
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>,
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>,
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>,
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>,
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>,
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>,
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>,
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>,
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>,
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>,
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>,
  image?: Maybe<AssetWhereInput>,
  category?: Maybe<CategoryWhereInput>,
  entryForms_every?: Maybe<EntryFormWhereInput>,
  entryForms_some?: Maybe<EntryFormWhereInput>,
  entryForms_none?: Maybe<EntryFormWhereInput>,
};

export type ProductWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
};

export type Query = {
   __typename?: 'Query',
  assets: Array<Maybe<Asset>>,
  categories: Array<Maybe<Category>>,
  products: Array<Maybe<Product>>,
  entryForms: Array<Maybe<EntryForm>>,
  entryFormContents: Array<Maybe<EntryFormContent>>,
  completeContents: Array<Maybe<CompleteContent>>,
  popups: Array<Maybe<Popup>>,
  asset?: Maybe<Asset>,
  category?: Maybe<Category>,
  product?: Maybe<Product>,
  entryForm?: Maybe<EntryForm>,
  entryFormContent?: Maybe<EntryFormContent>,
  completeContent?: Maybe<CompleteContent>,
  popup?: Maybe<Popup>,
  assetsConnection: AssetConnection,
  categoriesConnection: CategoryConnection,
  productsConnection: ProductConnection,
  entryFormsConnection: EntryFormConnection,
  entryFormContentsConnection: EntryFormContentConnection,
  completeContentsConnection: CompleteContentConnection,
  popupsConnection: PopupConnection,
  /** Fetches an object given its ID */
  node?: Maybe<Node>,
};


export type QueryAssetsArgs = {
  where?: Maybe<AssetWhereInput>,
  orderBy?: Maybe<AssetOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryCategoriesArgs = {
  where?: Maybe<CategoryWhereInput>,
  orderBy?: Maybe<CategoryOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryProductsArgs = {
  where?: Maybe<ProductWhereInput>,
  orderBy?: Maybe<ProductOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEntryFormsArgs = {
  where?: Maybe<EntryFormWhereInput>,
  orderBy?: Maybe<EntryFormOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEntryFormContentsArgs = {
  where?: Maybe<EntryFormContentWhereInput>,
  orderBy?: Maybe<EntryFormContentOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryCompleteContentsArgs = {
  where?: Maybe<CompleteContentWhereInput>,
  orderBy?: Maybe<CompleteContentOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPopupsArgs = {
  where?: Maybe<PopupWhereInput>,
  orderBy?: Maybe<PopupOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryAssetArgs = {
  where: AssetWhereUniqueInput
};


export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput
};


export type QueryEntryFormArgs = {
  where: EntryFormWhereUniqueInput
};


export type QueryEntryFormContentArgs = {
  where: EntryFormContentWhereUniqueInput
};


export type QueryCompleteContentArgs = {
  where: CompleteContentWhereUniqueInput
};


export type QueryPopupArgs = {
  where: PopupWhereUniqueInput
};


export type QueryAssetsConnectionArgs = {
  where?: Maybe<AssetWhereInput>,
  orderBy?: Maybe<AssetOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryCategoriesConnectionArgs = {
  where?: Maybe<CategoryWhereInput>,
  orderBy?: Maybe<CategoryOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryProductsConnectionArgs = {
  where?: Maybe<ProductWhereInput>,
  orderBy?: Maybe<ProductOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEntryFormsConnectionArgs = {
  where?: Maybe<EntryFormWhereInput>,
  orderBy?: Maybe<EntryFormOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEntryFormContentsConnectionArgs = {
  where?: Maybe<EntryFormContentWhereInput>,
  orderBy?: Maybe<EntryFormContentOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryCompleteContentsConnectionArgs = {
  where?: Maybe<CompleteContentWhereInput>,
  orderBy?: Maybe<CompleteContentOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPopupsConnectionArgs = {
  where?: Maybe<PopupWhereInput>,
  orderBy?: Maybe<PopupOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryNodeArgs = {
  id: Scalars['ID']
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
   __typename?: 'RGBA',
  r: Scalars['RGBAHue'],
  g: Scalars['RGBAHue'],
  b: Scalars['RGBAHue'],
  a: Scalars['RGBATransparency'],
};


/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  r: Scalars['RGBAHue'],
  g: Scalars['RGBAHue'],
  b: Scalars['RGBAHue'],
  a: Scalars['RGBATransparency'],
};


/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
   __typename?: 'RichText',
  raw?: Maybe<Scalars['RichTextAST']>,
  html?: Maybe<Scalars['String']>,
  markdown?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
};


export enum Status {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED'
}

export type PrintDataQueryVariables = {
  ids?: Maybe<Array<Scalars['ID']>>
};


export type PrintDataQuery = (
  { __typename?: 'Query' }
  & { products: Array<Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
    & { image: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'handle'>
    )>, category: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'order'>
      & { color: (
        { __typename?: 'Color' }
        & Pick<Color, 'hex'>
      ), signImage: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'handle'>
      )>, circleImage: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'handle'>
      )>, labelImage: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'handle'>
      )> }
    )> }
  )>> }
);

export type AppDataQueryVariables = {};


export type AppDataQuery = (
  { __typename?: 'Query' }
  & { entryFormContents: Array<Maybe<(
    { __typename?: 'EntryFormContent' }
    & Pick<EntryFormContent, 'title'>
    & { content: Maybe<(
      { __typename?: 'RichText' }
      & Pick<RichText, 'html'>
    )>, terms: Maybe<(
      { __typename?: 'RichText' }
      & Pick<RichText, 'html'>
    )> }
  )>>, completeContents: Array<Maybe<(
    { __typename?: 'CompleteContent' }
    & Pick<CompleteContent, 'title'>
    & { content: Maybe<(
      { __typename?: 'RichText' }
      & Pick<RichText, 'html'>
    )>, underSocialContent: Maybe<(
      { __typename?: 'RichText' }
      & Pick<RichText, 'html'>
    )> }
  )>>, categories: Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'order'>
    & { color: (
      { __typename?: 'Color' }
      & Pick<Color, 'hex'>
    ), signImage: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'handle'>
    )>, circleImage: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'handle'>
    )>, labelImage: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'handle'>
    )>, products: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
      & { image: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'handle'>
      )> }
    )>> }
  )>>, popups: Array<Maybe<(
    { __typename?: 'Popup' }
    & Pick<Popup, 'name'>
    & { content: (
      { __typename?: 'RichText' }
      & Pick<RichText, 'html'>
    ), terms: Maybe<(
      { __typename?: 'RichText' }
      & Pick<RichText, 'html'>
    )> }
  )>> }
);


export const PrintDataDocument = gql`
    query PrintData($ids: [ID!]) {
  products(where: {id_in: $ids}) {
    id
    image {
      handle
    }
    name
    category {
      id
      name
      order
      color {
        hex
      }
      signImage {
        handle
      }
      circleImage {
        handle
      }
      labelImage {
        handle
      }
    }
  }
}
    `;
export type PrintDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PrintDataQuery, PrintDataQueryVariables>, 'query'>;

    export const PrintDataComponent = (props: PrintDataComponentProps) => (
      <ApolloReactComponents.Query<PrintDataQuery, PrintDataQueryVariables> query={PrintDataDocument} {...props} />
    );
    

/**
 * __usePrintDataQuery__
 *
 * To run a query within a React component, call `usePrintDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintDataQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePrintDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PrintDataQuery, PrintDataQueryVariables>) {
        return ApolloReactHooks.useQuery<PrintDataQuery, PrintDataQueryVariables>(PrintDataDocument, baseOptions);
      }
export function usePrintDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrintDataQuery, PrintDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PrintDataQuery, PrintDataQueryVariables>(PrintDataDocument, baseOptions);
        }
export type PrintDataQueryHookResult = ReturnType<typeof usePrintDataQuery>;
export type PrintDataLazyQueryHookResult = ReturnType<typeof usePrintDataLazyQuery>;
export type PrintDataQueryResult = ApolloReactCommon.QueryResult<PrintDataQuery, PrintDataQueryVariables>;
export const AppDataDocument = gql`
    query AppData {
  entryFormContents(first: 1) {
    title
    content {
      html
    }
    terms {
      html
    }
  }
  completeContents(first: 1) {
    title
    content {
      html
    }
    underSocialContent {
      html
    }
  }
  categories(orderBy: order_ASC) {
    id
    name
    order
    color {
      hex
    }
    signImage {
      handle
    }
    circleImage {
      handle
    }
    labelImage {
      handle
    }
    products {
      id
      name
      image {
        handle
      }
    }
  }
  popups {
    name
    content {
      html
    }
    terms {
      html
    }
  }
}
    `;
export type AppDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AppDataQuery, AppDataQueryVariables>, 'query'>;

    export const AppDataComponent = (props: AppDataComponentProps) => (
      <ApolloReactComponents.Query<AppDataQuery, AppDataQueryVariables> query={AppDataDocument} {...props} />
    );
    

/**
 * __useAppDataQuery__
 *
 * To run a query within a React component, call `useAppDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppDataQuery, AppDataQueryVariables>) {
        return ApolloReactHooks.useQuery<AppDataQuery, AppDataQueryVariables>(AppDataDocument, baseOptions);
      }
export function useAppDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppDataQuery, AppDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppDataQuery, AppDataQueryVariables>(AppDataDocument, baseOptions);
        }
export type AppDataQueryHookResult = ReturnType<typeof useAppDataQuery>;
export type AppDataLazyQueryHookResult = ReturnType<typeof useAppDataLazyQuery>;
export type AppDataQueryResult = ApolloReactCommon.QueryResult<AppDataQuery, AppDataQueryVariables>;