import React, { Component } from "react";
import NProgress from "nprogress";

export const AsyncComponent = (importComponent: any) => {
  class AsyncComponent extends Component {
    constructor(props: any) {
      super(props);
      NProgress.start();
      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component: component
      });

      NProgress.done();
    }

    componentWillUnmount() {
      NProgress.done();
    }

    render() {
      // @ts-ignore
      const C = this.state.component;

      return C ? <C {...this.props} /> : <div className="pageloader is-active" />;
    }
  }

  return AsyncComponent;
};

export default AsyncComponent;
